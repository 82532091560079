import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonLabel,
    IonMenuButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { arrowBackOutline, logoApple, logoAppleAppstore } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { quraniIosAppLink } from './QuraniSideMenu'

export function QuraniToolbar(props: {
    title: string
    backParams?: { type: 'none' | 'back' } | { type: 'custom'; url: string }
}) {
    const { title, backParams } = props
    const history = useHistory()
    return (
        <IonHeader>
            <IonToolbar>
                {backParams?.type === 'none' && backParams === undefined ? undefined : (
                    <IonButtons slot='start'>
                        {backParams?.type === 'back' ? (
                            <IonBackButton />
                        ) : backParams?.type === 'custom' ? (
                            <IonButton
                                onClick={() => {
                                    history.push(backParams.url)
                                }}
                            >
                                <IonIcon slot='icon-only' icon={arrowBackOutline} />
                            </IonButton>
                        ) : undefined}
                    </IonButtons>
                )}

                <IonTitle>{title}</IonTitle>
                <IonButtons slot='end'>
                    <IonButton
                        href={quraniIosAppLink.link}
                        target='_blank'
                        class='ion-align-items-center'
                        onClick={quraniIosAppLink.additionalClickHandler}
                    >
                        <IonIcon icon={logoAppleAppstore} slot='start' />
                        <IonLabel class='ion-hide-sm-down'>{quraniIosAppLink.title}</IonLabel>
                    </IonButton>
                    <IonMenuButton />
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}
