import moment, { Moment } from 'moment'
import { v4 as uuid } from 'uuid'
import { commonStrings } from '../consts'
import { Action0, Func1, NumberMap, SgSerializable, StringMap } from '../types'
import { logDebug } from './Logging'

const arabicNumerals = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']
// const leftArabicParenthesis = '﴾'
// const rightArabicParenthesis = '﴿'

export function secondsToHoursDecimal(seconds: number) {
    return seconds / 60 / 60
}

export function hoursToSeconds(hours: number) {
    return hours * 60 * 60
}

export function getAbsoluteSecondsFromDayStart(time: Moment, roundToNearestMinute?: boolean) {
    const dayStart = moment(time).startOf('day')
    const absoluteSeconds = time.diff(dayStart, 'second')
    return roundToNearestMinute ? Math.round(absoluteSeconds / 60) * 60 : absoluteSeconds
}

export function formatAsArabicWithParentheses(n: number, reverse?: boolean): string {
    const arabicNumeral = n.toString().replace(/[0-9]/g, w => arabicNumerals[+w])

    // return commonStrings.ayahEnd + arabicNumeral

    if (reverse) {
        return commonStrings.arRightParethesis + arabicNumeral + commonStrings.arLeftParenthesis
    } else {
        return commonStrings.arLeftParenthesis + arabicNumeral + commonStrings.arRightParethesis
    }
}

export function isNotNull<T>(param: T): param is Exclude<T, null | undefined> {
    return param !== undefined && param !== null
}

// export function generateIdForFirestore() {
//     return uuid()
// }

export function generateId() {
    return uuid()
}

/**https://stackoverflow.com/questions/3269434/whats-the-most-efficient-way-to-test-two-integer-ranges-for-overlap */
export function checkIfRangesIntersect(
    range1: { start: number; end: number },
    range2: { start: number; end: number }
) {
    // -1 end since end is exclusive
    return range1.start <= range2.end - 1 && range2.start <= range1.end - 1
}

export function getNextIndex(current: number, array: unknown[]) {
    return getNext(current, array.length - 1, 0)
}
export function getPreviousIndex(current: number, array: unknown[]) {
    return getPrevious(current, array.length - 1, 0)
}
export function getNext(current: number, maxInclusive: number, minInclusive: number) {
    return current === maxInclusive ? minInclusive : current + 1
}
export function getPrevious(current: number, maxInclusive: number, minInclusive: number) {
    return current === minInclusive ? maxInclusive : current - 1
}

export function getNextQuranPageNbr(current: number) {
    return getNext(current, 604, 1)
}
export function getPreviousQuranPageNbr(current: number) {
    return getPrevious(current, 604, 1)
}

export function getNextQuranChapterId(current: number) {
    return getNext(current, 114, 1)
}
export function getPreviousQuranChapterId(current: number) {
    return getPrevious(current, 114, 1)
}

export function getNextQuranVerseNbr(current: number, surahVerseCount: number) {
    return getNext(current, surahVerseCount, 1)
}
export function getPreviousQuranVerseNbr(current: number, surahVerseCount: number) {
    return getPrevious(current, surahVerseCount, 1)
}

export function throwIfNull<T>(param: T): NonNullable<T> | never {
    if (isNotNull(param)) {
        return param
    }

    throw new Error('Non-null assertion failed')
}
export function throwSinceNull<T>(): never {
    throw new Error('Non-null assertion failed')
}
/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function delay(durationMS: number) {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            resolve()
        }, durationMS)
    })
}

export function tryGet<T, K extends keyof T>(obj: T | undefined, key: K): T[K] | undefined {
    if (obj) {
        return obj[key]
    }
}

export function executeImmediateInterval(action: Action0, periodMS: number) {
    action()
    return setInterval(action, periodMS)
}

export function getTimezoneOffsetForLongitudeHours(lng: number) {
    // return Math.round((lng / 15) * 100) / 100
    return Math.round(lng / 15) // most countries round their time zone
}

export function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
}

export function formatAsHoursMinutesSeconds(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600)
    const remainingSeconds = totalSeconds - hours * 3600
    const minutes = Math.floor(remainingSeconds / 60)
    const seconds = Math.floor(remainingSeconds - minutes * 60)
    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`
}

export function pad(n: number, width: number): string {
    const prefix = '0'
    const str = n + ''
    return str.length >= width ? str : new Array(width - str.length + 1).join(prefix) + str
}

export function cloneThenReplaceProperty<T, K extends keyof T>(
    dictionary: T,
    key: K,
    value: T[K]
): T {
    return { ...dictionary, [key]: value }
}
export function cloneThenReplaceProperties<T, K extends keyof T>(
    dictionary: T,
    updates: Partial<T>
): T {
    return { ...dictionary, ...updates }
}

export function parseIntUndefinedIfNan(str: string): number | undefined {
    const n = parseInt(str)
    if (isNaN(n)) {
        return undefined
    }

    return n
}

export function getKeys<T>(obj: T): (keyof T)[] {
    return Object.getOwnPropertyNames(obj) as (keyof T)[]
}

export function getValues<T>(obj: T): T[keyof T][] {
    return (Object.keys(obj) as (keyof T)[]).map(key => obj[key])
}

export function getKeyValuePairs<T>(obj: T) {
    return (Object.keys(obj) as (keyof T)[]).map(key => ({ key, value: obj[key] }))
}

export function roundToDecimalPlaces(num: number, decimalPlaces: number) {
    const factor = 10 ** decimalPlaces
    return Math.round((num + Number.EPSILON) * factor) / factor
}
// export function roundToDecimalPlaces(num: number, decimalPlaces: number) {
//     const factor = 10 ** decimalPlaces
//     return Math.round((num + Number.EPSILON) * factor) / factor
// }

export function roundToDigit(num: number, powerOf10: number) {
    return Math.floor(num / 10 ** powerOf10) * 10 ** powerOf10
}

/** Number and String keys actually return the same value in javascript */
export function arrayToDictionary<T>(array: T[], getKey: Func1<T, string | number>): StringMap<T> {
    const dictionary: StringMap<T> = {}
    for (const item of array) {
        dictionary[getKey(item)] = item
    }

    return dictionary
}

export function dictionaryToArray<T>(dictionary: StringMap<T>) {
    return getKeyValuePairs(dictionary)
}

export function pushAll<T>(targetArray: Array<T>, elements: Array<T>) {
    for (const x of elements) {
        targetArray.push(x)
    }
}

export function toProperCase(str: string) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export function getIOSAppStoreUrl(appID: string): string {
    return `https://apps.apple.com/app/id${appID}`
}

export function getIOSAppStoreUrlForMobile(appID: string): string {
    return `itms-apps://apps.apple.com/app/id${appID}`
}

export function getAndroidPlayStoreUrlForWeb(packageName: string): string {
    return `https://play.google.com/store/apps/details?id=${packageName}`
}

export function getAndroidPlayStoreUrlForMobile(packageName: string): string {
    return `market://details?id=${packageName}`
}

export function getWindowsStoreUrl(appId: string): string {
    return `https://www.microsoft.com/store/apps/${appId}`
}

export function binarySearchForIndex<T>(sortedArray: T[], predicate: (x: T) => number): number {
    let m = 0
    let n = sortedArray.length - 1
    while (m <= n) {
        // eslint-disable-next-line no-bitwise
        const k = (n + m) >> 1
        const cmp = predicate(sortedArray[k])
        if (cmp > 0) {
            m = k + 1
        } else if (cmp < 0) {
            n = k - 1
        } else {
            return k
        }
    }
    return -m - 1
}

export function getArabicCharCount(str: string): number {
    const res = str.match(/[\u0600-\u06FF]/g)
    return res ? res.length : 0
}

// export function *flatten<TParent, TChild>(parents: TParent[], getChildren: (parent: TParent) => TChild[]): Iterable<TChild>{
//     for(let parent of parents){
//         for(let child of getChildren(parent)){
//             yield child
//         }
//     }
// }

export function isEmail(str: string): boolean {
    const arr = str.split('@').filter(x => x.trim())
    return arr.length === 2 && arr[1].includes('.')
}

// export function formatBytes(bytes: number, decimals = 2): string {
//     if (bytes === 0) return '0 Bytes'

//     const k = 1024
//     const dm = decimals < 0 ? 0 : decimals
//     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

//     const i = Math.floor(Math.log(bytes) / Math.log(k))

//     return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
// }

// export function formatAsHoursMinutesSeconds(totalSeconds: number): string {
//     const hours = Math.floor(totalSeconds / 3600)
//     const remainingSeconds = totalSeconds - hours * 3600
//     const minutes = Math.floor(remainingSeconds / 60)
//     const seconds = Math.floor(remainingSeconds - minutes * 60)
//     return `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
// }

// don't encode
export function formatAsQueryString(params?: Record<string, string | number | boolean>): string {
    if (!params) {
        return ''
    }
    const str = Object.getOwnPropertyNames(params)
        .filter(key => params[key])
        .map(key => `${key}=${params[key]}`)
        .join('&')
    if (str.isBlank()) {
        return ''
    }
    return `?${str}`
}

export function getHrefFromAbsoluteUrl(absoluteUrl: string): string {
    const arr = absoluteUrl.split('//').filter(x => x.trim())
    const noScheme = arr.slice(1, arr.length).join('//')
    const arr2 = noScheme.split('/').filter(x => x.trim())
    return arr2.slice(1, arr2.length).join('/')
    // .split('/', 2).filter(x => x.trim())[1]
}

// don't encode
export function appendQueryString(
    href: string,
    params?: Record<string, string | number | boolean>
): string {
    if (!params) {
        return href
    }
    const raw = `${href}${formatAsQueryString(params)}`
    return raw
}

// todo: optimize this by using flatMap in es2019 or iterables
export function flatten<T>(arr: T[][]): T[] {
    const res: T[] = []
    for (const parent of arr) {
        for (const child of parent) {
            res.push(child)
        }
    }

    return res
}

export function defaultIfNull<T>(value: T | null, def: T): T {
    return value === null ? def : value
}

export function generateOrderedId(): string {
    const now = moment.utc().valueOf()
    return `${now}-${uuid()}`
}

export function showIf(condition: boolean, elementCreator: () => JSX.Element): JSX.Element | null {
    return !condition ? null : elementCreator()
}

export function render(elementCreator: () => JSX.Element): JSX.Element {
    return elementCreator()
}

export function parseIntElseNull(str: string | undefined | null): number | null {
    if (!str) {
        return null
    }
    const val = parseInt(str)
    if (isNaN(val)) {
        return null
    }

    return val
}

export function parseFloatElseNull(str: string | undefined | null): number | null {
    if (!str) {
        return null
    }
    const val = parseFloat(str)
    if (isNaN(val)) {
        return null
    }

    return val
}

export function zeroIfNaN(n: number): number {
    return isNaN(n) ? 0 : n
}

export function getPropertyNamesOfClassInstance(obj: unknown): string[] {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
}

export function getPropertyNamesOfPureObject(obj: unknown): string[] {
    return Object.getOwnPropertyNames(obj)
}

export function getCommonPropertyNamesBetweenClassInstanceAndPureObject(
    classInstance: unknown,
    pureObject: unknown
): string[] {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(classInstance)).filter(x =>
        Object.prototype.hasOwnProperty.call(pureObject, x)
    )
}

export function getCommonPropertyNamesBetweenPureObjects(
    pureObject1: unknown,
    pureObject2: unknown
): string[] {
    return Object.getOwnPropertyNames(pureObject1).filter(x =>
        Object.prototype.hasOwnProperty.call(pureObject2, x)
    )
}

export function getIntersection<T>(prototype: T, source: T): T {
    const obj: StringMap<unknown> = {}
    for (const p of getPropertyNamesOfPureObject(prototype)) {
        obj[p] = (source as StringMap<unknown>)[p]
    }
    return obj as T
}

// export function getIntersectionBetweenPureObjects<T1, T2>(target: T1, source: T2): Intersection<T1,T2>{
//     const obj = {} as any
//     for(let p of this.getCommonPropertyNamesBetweenPureObjects(target, source)){
//         obj[p] = (source as any)[p]
//     }
//     return obj
// }

// export function assignCommonProperties(target: any, source: any){
//     // for-in doesn't work; also, we should use the prototype
//     let props = Object.getOwnPropertyNames(Object.getPrototypeOf(source))
//     for(let p of props){
//         // shouldn't we use prototype of target since if it's a class, then it won't work?
//         if(target.hasOwnProperty(p)){
//             target[p] = source[p]
//         }
//     }
// }

// export function batchObject(db: FirebaseFirestore): BatchObject {
//     const batch = db.batch()
//     const onCommitted = new Subject<void>()
//     return {
//         batch,
//         onCommitted,
//         commit: async () => {
//             await batch.commit()
//             onCommitted.next()
//         },
//     }
// }

// export function checkForShallowEquality<T>(a: T, b: T) {
//     return (
//         Object.getOwnPropertyNames(a).length === Object.getOwnPropertyNames(b).length &&
//         Object.getOwnPropertyNames(a).every(key => a[key as keyof T] === b[key as keyof T])
//     )
// }

export function removeFocusOnActiveHtmlElement() {
    ;(document.activeElement as HTMLElement | undefined)?.blur()
}

export function getCacheKeyForObject(prefix: string, params: StringMap<SgSerializable>) {
    const arr: string[] = [prefix]
    for (const key of Object.getOwnPropertyNames(params)) {
        const value = params[key]
        if (!value) {
            continue
        }
        arr.push(value.toString())
    }
    return arr.join('-')
}
export function getCacheKeyForArray(prefix: string, arr: SgSerializable[]) {
    return `${prefix}-${arr.map(x => x.toString()).join('-')}`
}

export function removeDuplicatesFromArray<T>(arr: T[]): T[] {
    return [...new Set(arr)]
}
export function filterDuplicates<T>(arr: T[], getKey: Func1<T, string>): T[] {
    const newArr: T[] = []
    const map: StringMap<T> = {}
    for (const item of arr) {
        const key = getKey(item)
        if (map[key]) {
            logDebug(`duplicate: ${key}`)
            continue
        }

        map[key] = item
        newArr.push(item)
    }

    return newArr
}

export function findInArrayGroup<T>(
    keyToFind: string,
    groups: T[][],
    getKey: Func1<T, string>
): undefined | { groupIndex: number; itemIndex: number } {
    let groupIndex = -1
    let itemIndex = -1
    for (let g = 0; g < groups.length; g++) {
        const group = groups[g]
        itemIndex = group.findIndex(x => getKey(x) === keyToFind)
        if (itemIndex >= 0) {
            groupIndex = g
            break
        }
    }

    return groupIndex === -1 ? undefined : { groupIndex, itemIndex }
}

export function findInArrayMap<T>(
    keyToFind: string,
    map: NumberMap<T[]>,
    getKey: Func1<T, string>
): undefined | { group: T[]; itemIndex: number } {
    // let groupIndex = -1
    let itemIndex = -1
    let group: T[] | undefined
    for (const g in map) {
        const currentGroup = throwIfNull(map[g])
        itemIndex = currentGroup.findIndex(x => getKey(x) === keyToFind)
        if (itemIndex >= 0) {
            group = currentGroup
            break
        }
    }

    return group === undefined ? undefined : { group, itemIndex }
}

export function getTimeStringSinceDate(date: Date) {
    const now = moment() // needs to be fresh otherwise will show negative for new ones
    const dateCreated = moment(date)
    const daysDiff = now.diff(now, 'days')
    const hoursDiff = daysDiff ? undefined : now.diff(dateCreated, 'hour')
    const minutesDiff = hoursDiff ? undefined : now.diff(dateCreated, 'minute')
    const secondsDiff = minutesDiff ? undefined : now.diff(dateCreated, 'second')
    const actualNum = daysDiff || hoursDiff || minutesDiff || secondsDiff
    const singular = daysDiff ? 'day' : hoursDiff ? 'hour' : minutesDiff ? 'minute' : 'second'
    // return { actualNum, daysDiff, hoursDiff, minutesDiff }
    return !actualNum ? '' : `${actualNum} ${singular}${actualNum > 1 ? 's' : ''} ago`
}

export function stringArrayUnion(params: {
    stringArray: string | undefined
    itemToAdd: string
    delimiter: string
}): string {
    const { stringArray, itemToAdd, delimiter } = params
    const arr = stringArray?.split(delimiter) ?? []
    if (!arr.includes(itemToAdd)) {
        arr.push(itemToAdd)
    }

    return arr.join(delimiter)
}
