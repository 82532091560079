const str = {
    prayer: {
        prayerNames: {
            fajr: 'Fajr',
            sunrise: 'Sunrise',
            dhuhr: 'Dhuhr',
            asr: 'Asr',
            maghrib: 'Maghrib',
            isha: 'Isha',
        },
        prayerTimes: 'Prayer Times',
        location: {
            permissionNotGranted: 'Location permission is not granted.',
            calculationMayBeWrong: 'Prayer times calculation may not work as expected.',
            providerNotAvailable: 'Location provider not available.',
            requestTimeout: 'Location request timed out.',
            googlePlayError: 'Google play service is not installed or has an older version.',
            wrongLocationSettings:
                'Location service is not enabled or location mode is not appropriate for the current request.',
            someErrorOccured: 'Some error occurred while retrieving your location.',
        },
    },
    quran: 'Quran',
    premium: 'Premium',
    khatmMode: 'Khatm mode',
    listen: 'Listen',
    playlists: 'Playlists',
    mushaf: 'Mushaf',
    tafsirKathir: 'Tafsir Ibn-Kathir',
    tafsirMaududi: 'Tafsir Maududi',
    maarifQuran: 'Maarif Al-Quran',
    sababNuzul: 'Sabab An-nuzul',
    wordByWord: 'Word by Word',
    grammar: 'Grammar',
    settings: 'Settings',
    translators: 'Translators',
    translations: 'Translations',
    noGrammarForStopmarks: 'No grammar entry for stopmarks',
    root: 'Root',
    lemma: 'Lemma',
    category: 'Category',
    subCategory: 'Subcategory',
    position: 'Position',
    previous: 'Previous',
    next: 'Next',
    listenToEntireQuranOrdered: 'Listen to the entire Quran in order',
    read: 'Read',
    readEntireQuran: 'Finish reading the entire Quran',
    readEntireQuranMushaf: 'Finish reading the entire Quran as a Mushaf',
    pickSurahListen: 'Pick the surah to listen to',
    browseQuran: 'Browse the Quran in any order',
    readQuranMushaf: 'Read the Quran as a Mushaf',
    unlockFeatures: 'Unlock all features',
    supportAppRating: 'Support the app by rating it in the App Store',
    rateApp: 'Rate the App',
    visit: 'Visit',
    webVersion: 'Web Version',
    streamQuranRecitations: "Stream Quran recitations on the app's YouTube channel",
    likeAppFacebook: "Like the app's page on Facebook for more Islamic content",
    followAppTwitter: "Follow the app's page on Twitter for more Islamic content",
    alreadyDownloading: 'Already downloading',
    about: 'About',
    more: 'More',
    loginRequired: 'You must be logged-in to do this',
    logout: 'Logout',
    manuallyDownloadingNotAllowed: 'Manually downloading is not allowed while downloading all',
    cancelOngoingDownloadFirst: 'Cancel ongoing download first before downloading all',
    queueingDownloadAll: 'Queuing download of all files for the reciter ...',
    downloadingSurah: 'Downloading Surah',
    downloadingNextSurah: 'Downloading next surah',
    surah: 'Surah',
    someErrorOccuredPlayingMp3: 'Some error occured while playing the MP3 file',
    delete: 'Delete',
    deleteFile: 'Delete File',
    thisWillDeleteInDisk: 'This will delete the MP3 file on the disk',
    surahNowDownloaded: 'Surah is not yet downloaded',
    redownloadFile: 'Redownload File',
    thisWillDeleteFileAndRedownload: 'This will delete the MP3 file on the disk and redownload it',
    redownload: 'Redownload',
    backgroundDownloadNotYetSupportedMessage:
        'Background downloading is not yet supported, so please keep the app active while downloading otherwise the download may be canceled.',
    manualSelectionNotAllowedKhatm: 'Manual selection of surah is not allowed in Khatm mode',
    manualDownloadingNotAllowedWhileDownloadingAll:
        'Manual downloading is disabled while downloading all',
    word: 'Word',
    cancel: 'Cancel',
    scrollToPage: 'Scroll to Page',
    enterPageNumberScroll: 'Enter the page number to scroll to:',
    invalidPageNumberInput: 'Invalid page number input',
    premiumStrings: {
        groups: [
            {
                title: 'Free',
                data: [
                    {
                        title: 'Tafsir Ibn-Kathir',
                        body: "Tafseer al-Qurʾān al-ʿAẓeem (c. 1300) by Ibn-Kathir is one of the most famous Quranic Tafsirs. It links certain hadith and sayings of the sahaba to verses of the Qur'an, and avoided the use of Isra'iliyyats.",
                    },
                    {
                        title: 'Word by Word',
                        body: 'See the breakdown of each verse word by word and their corresponding meaning and transliteration in English',
                    },
                    {
                        title: 'Kathm Modes',
                        body: "The best way to finish the entire Qur'an, through listening or through reading",
                    },
                    {
                        title: 'Mushaf Mode',
                        body: 'Read the Quran just like the original Mushaf',
                    },
                    {
                        title: 'All 49 Translations',
                        body: 'Read each verse of the Quran along with its translation in multiple languages',
                    },
                    {
                        title: 'Most Reciters',
                        body: 'Listen to the recitation of the Quran by more than a hundred Quranic reciters/qaris',
                    },
                    {
                        title: 'Tafsir Maududi (Partial)',
                        body: 'Half of Tafsir Maududi is available for free',
                    },
                    {
                        title: 'Maarif Al-Quran (Partial)',
                        body: 'Half of Maarif Al-Quran is available for free',
                    },
                ],
            },
            {
                title: 'Premium',
                data: [
                    {
                        // every second verse
                        title: 'Tafsir Maududi (Complete)',
                        body: 'Tafhim-ul-Quran (1972) by Syed Abul Ala Maududi is a combination of orthodox and modernist interpretation and has deeply influenced modern Islamic thought. It is more than a traditional commentary on the scripture as it contains discussions and debates regarding economics, sociology, history, and politics.',
                    },
                    {
                        // every second verse
                        title: 'Maarif Al-Quran Tafsir (Complete)',
                        body: "Ma'ariful Qur'an (c. 1970) is an eight-volume tafsir of the Quran written by Pakistani Islamic scholar Mufti Muhammad Shafi. Originally written in Urdu, it is the most prominent work of its author.",
                    },
                    {
                        // all
                        title: 'Sabab Annuzul',
                        body: "Kitab asbab al-Nuzul ('Book of occasions of revelation') by Ali ibn Ahmad al-Wahid (c. 1075) is the earliest and the most important work in the study of the historical context of Quranic revelations. Al-Wahidi mentions occasions of about 570 verses out of 6236 verses of the Quran. Wahidi's work is not only the first attempt to collect all the material regarding the occasions of revelation in one single volume, but it is also the standard upon which all subsequent works were based.",
                    },
                    {
                        // every second token
                        title: 'Word Grammar',
                        body: 'See the breakdown of each Quranic word into segments and their corresponding grammar',
                    },
                    {
                        title: 'All Reciters',
                        body: 'Unlock all the reciters and listen to any of them',
                    },
                    {
                        title: 'Everything',
                        body: 'Unlock all features, present and future insha Allah',
                    },
                ],
            },
            {
                title: 'Soon',
                data: [
                    {
                        title: 'More Tafsirs',
                        body: 'More tafsirs in the future insha Allah including Tafsir Al-Tabari, etc.',
                    },
                    {
                        title: 'All 8 Books of Hadith',
                        body: 'All major hadith collections including Sahih Al-Bukhari, Sahih Muslim, Sunan Abu Dawud, Sunan Al-Trimidhi, Sunan Al-Nasai, and Sunan Ibn Majah',
                    },
                    {
                        title: 'Hans Wehr Dictionary',
                        body: 'The Dictionary of Modern Written Arabic (1961) is an Arabic-English dictionary compiled by Hans Wehr and edited by J Milton Cowan. It is the most widely used Arabic-English dictionary.',
                    },
                    {
                        title: 'Lane Dictionary: Arabic-English Lexicon',
                        body: 'The Arabic–English Lexicon is an Arabic–English dictionary compiled by Edward William Lane (died 1876). It consists of Arabic words defined and explained in the English language. But Lane does not use his own knowledge of Arabic to give definitions to the words. Instead, the definitions are taken from older Arabic dictionaries, primarily medieval Arabic dictionaries. Lane translates these definitions into English, and he carefully notes which dictionaries are giving which definitions.',
                    },
                    {
                        title: 'Lisan Al-Arab Dictionary',
                        body: "Lisān al-ʿArab [ar] (لسان العرب, 'Tongue of Arabs') was completed by Ibn Manzur in 1290. Occupying 20 printed book volumes (in the most frequently cited edition), it is the best known dictionary of the Arabic language, as well as one of the most comprehensive.",
                    },
                    {
                        title: 'Quranic Miracles',
                        body: '',
                    },
                    {
                        title: 'Duas',
                        body: '',
                    },
                    {
                        title: 'Prayer Times',
                        body: '',
                    },
                    {
                        title: 'Quran Vocabulary',
                        body: '',
                    },
                    {
                        title: 'Quran Memorization',
                        body: '',
                    },
                    {
                        title: 'Stream Quranic Recitations',
                        body: '',
                    },
                    {
                        title: 'Advanced Search',
                        body: '',
                    },
                ],
            },
        ],
        getPremium: 'Get Premium',
        restorePremium: 'Restore Premium',
        restorePremiumMessage:
            'If you just reinstalled the app but previously purchased the premium version, then click this to restore it.',
    },
    searchEllipsis: 'Search ...',
    style: 'Style',
    mp3Files: 'MP3 Files',
    size: 'Size',
    duration: 'Duration',
    thisWillDeleteFilesForReciter: 'This will delete all files for the reciter',
    deleteAll: 'Delete All',
    defaultReciterSet: 'Default reciter set to',
    settingsStrings: {
        groups: {
            resetListen: {
                title: "Reset 'Listen' progress",
                subtitle:
                    'This will reset your listening progress back to the beginning of the Quran',
            },
            resetRead: {
                title: "Reset 'Read' progress",
                subtitle:
                    'This will reset your reading progress back to the beginning of the Quran',
            },
            resetMushaf: {
                title: "Reset 'Mushaf' progress",
                subtitle: 'This will reset your mushaf progress back to the beginning of the Quran',
            },
            selectTranslations: {
                title: 'Select Translations',
            },
            showHadithArabic: {
                title: 'Toggle Show Arabic in Hadith',
            },
            showHadithEnglish: {
                title: 'Toggle Show English in Hadith',
            },
        },
    },
    selectTranslationInSettings: 'Select a translation in the settings first',
    details: 'Details',
    translationShownWhenClicked: "Translations shown when 'Translations' button is clicked",
    verses: 'Verses',
    tranlsationShownWithList: 'Translations shown along with the list of verses',
    noEntryAvailableForAyah: 'No entry available for this ayah',
    nextSurah: 'Next Surah',
    scrollToVerse: 'Scroll to Verse',
    enterVerseNumberScroll: 'Enter the verse number to scroll to:',
    invalidVerseNumber: 'Invalid verse number input',
    errorOccuredWhileScroll: 'Some error occured while trying to scroll to verse',
    loading: 'Loading',

    premiumFeaturesUnlocked: 'Premium features are now unlocked',
    purchaseError: 'Purchase Error',
    restoringPremiumEllipsis: 'Restoring premium ...',
    successfullyRestored: 'Successfully restored premium version',
    noPurchaseRecord: 'No purchase record is found for the current account.',
    unableToConnectStore: 'Unable to connect to the store',
    premiumMessages: {
        maududi:
            "Half of Tafsir Maududi is a premium content (every odd surah number). Please check the 'Premium' page to learn more.",
        sabab: "Sabab Annuzul is a premium content. Please check the 'Premium' page to learn more.",
        grammar:
            "Breakdown of each Quranic word and their corresponding grammar is a premium content. Please check the 'Premium' page to learn more.",
        reciter:
            "Some of the recitations are premium contents. Please check the 'Premium' page to learn more.",
        maarif: "Half of Maarif Al-Quran is a premium content (every odd surah number). Please check the 'Premium' page to learn more.",
    },
    launchingGooglePlayEllipsis: 'Launching Google Play ...',
    errorPurchasing: 'Error purchasing',
    verseNumber: 'Verse Number',
    scroll: 'Scroll',
}

// should be separate to be able to access strings
const get = {
    listenTitle(isKhatm: boolean) {
        return str.listen + (isKhatm ? ` (${str.khatmMode})` : '')
    },
} as const

export default { ...str, getters: get }
