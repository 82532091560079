import { commonFonts } from './Consts'

export const fonts = {
    ...commonFonts,
    short: 'HappyMonkey-Regular',
    shortBold: 'Comfortaa-Bold',
    long: 'OpenSans-Regular',
    longBold: 'OpenSans-Bold',
    // short: 'monospace',
    // shortBold: 'monospace',
    // long: 'sans-serif',
    // longBold: 'sans-serif',

    openSans: 'OpenSans-Regular',
    openSansBold: 'OpenSans-Bold',
    comfortaa: 'Comfortaa-Regular',
    comfortaaBold: 'Comfortaa-Bold',
    happyMonkey: 'HappyMonkey-Regular',
    // overlock: 'Overlock-Regular', // too small
    // specialElite: 'SpecialElite-Regular', // too informal
} as const
export const arabicFonts = {
    // short: 'Tajawal-Regular',
    short: 'Al-Qalam-Quran',
    long: 'Al-Qalam-Quran',
    scheherazadeBold: 'Scheherazade-Bold', // - kasra with shadda is on top
    mequran: 'me_quran', // + easiest to read, - but fatha and maad are crossed
    qalam1: 'Al-Qalam-Quran', // + most beautiful, - nun is displayed instead of character when maad is silent
    tajawal: 'Tajawal-Regular',
    // qalam2: 'AlQalamQuranMajeed2', // bold version of the first
    // pdms: '_PDMS_Saleem_QuranFont', // arabic numerals too small
    // scheherazadeReg: 'Scheherazade', // can't display waw and ya mad properly unlike the bold type
    // madina: 'Quran-Standard', // broken also
    // hidayat: 'noorehidayat', // broken
} as const
export const appStringConsts = {
    appName: 'Qurani.co',
    youtube: 'YouTube',
    facebook: 'Facebook',
    emptyTime: '--:--:--',
} as const
export const maxMushafPageCount = 604
export const itemSummaryLength = 300
export const forwardAbsoluteSeconds = 30
export const backwardAbsoluteSeconds = 15 // absolute
export const defaultScreenLogDuration = 5000
export const gmDevId = 'GpOfMUQmX6gGOf6D4ZkIs9pXcuC2'
export const sDevId = 'UCGeF52spqbyIOVnRjAdcaMZcfi2'
export const quraniAppleAppId = '1536292666'
export const quraniAndroidPackageName = 'qurani.co'
export const quraniAppIdOrPackageName = {
    ios: quraniAppleAppId,
    // TODO update this
    android: quraniAndroidPackageName,
}
export const privacyPolicyUrl =
    'https://www.freeprivacypolicy.com/live/38be7dd9-6564-49c0-9ab5-1a6bf1e1f8cf'
export const eulaUrl = 'https://justpaste.it/82wds'
