// import * as Sentry from '@sentry/react-native'
import { logDebug } from './Logging'
import { toast } from './Modals'
// import { PurchaseError } from 'react-native-iap'
// TODO core should not reference platform
// import { toast } from '../../platform'

type SgError = Error | string

// export function handleErrorSilently(errParam: SgError) {
//     const err = getActualError(errParam)
//     logError(err)
//     console.warn(err)
// }

const firestoreGetNotFoundFromCacheErrorCode = 'firestore/unavailable'
function checkIfFirestoreGetError(err: unknown) {
    // https://github.com/invertase/react-native-firebase/issues/1441
    return (err as { code?: string }).code === firestoreGetNotFoundFromCacheErrorCode
}

export function handleErrorAndDisplayPermanentWarning(err: SgError, params?: unknown) {
    handleError(err, params)
}

/**
 * Will log and toast (unless firestore get error)
 */
export function handleError(errParam: SgError, params?: unknown) {
    const err = handleErrorSilently(errParam, params)
    if (!err) {
        return
    }

    toast(err.message)
}
export function handleErrorSilently(errParam: SgError, params?: unknown) {
    const err = getActualError(errParam)

    if (checkIfFirestoreGetError(err)) {
        logDebug((errParam as { message?: string }).message || JSON.stringify(errParam))
        return undefined
    }

    const paramsString = !params
        ? undefined
        : typeof params === 'string'
        ? params
        : JSON.stringify(params)

    logError(err)
    console.warn(err, paramsString)
    return err
}

// export function handleCriticalErrorAndShowErrorScreen(err: SgError) {
//     // TODO show error screen
//     // logError(err)
//     // console.warn(err)
//     handleError(err)
// }

function logError(err: Error) {
    // TODO log error
    // TODO log in sentry
    // Sentry.captureException(err)
}

function getActualError(errParam: SgError) {
    return typeof errParam === 'string' ? new Error(errParam) : errParam
}
