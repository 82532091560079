import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonFooter,
    IonIcon,
    IonSpinner,
    IonToolbar,
} from '@ionic/react'
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import { ReactNode, useMemo } from 'react'

type FooterNavigationParam = {
    link: string
    isDisabled: boolean
}

export type QuraniFooterNavigationParams = {
    previous: FooterNavigationParam
    next: FooterNavigationParam
    leftToRight?: boolean
}

export function QuraniPageContent(props: {
    loading: boolean
    children?: ReactNode
    footerNavigationParams?: QuraniFooterNavigationParams
    cardHeaderContent?: ReactNode
}) {
    const { loading, children, footerNavigationParams, cardHeaderContent } = props

    const arrowButtons = useMemo<(FooterNavigationParam & { icon: string })[]>(
        () =>
            !footerNavigationParams
                ? []
                : [
                      {
                          link: footerNavigationParams.next.link,
                          isDisabled: footerNavigationParams.next.isDisabled,
                          icon: footerNavigationParams?.leftToRight
                              ? chevronForwardOutline
                              : chevronBackOutline,
                      },
                      {
                          link: footerNavigationParams.previous.link,
                          isDisabled: footerNavigationParams.previous.isDisabled,
                          icon: footerNavigationParams?.leftToRight
                              ? chevronBackOutline
                              : chevronForwardOutline,
                      },
                  ]?.sort((a, b) => (footerNavigationParams.leftToRight ? -1 : 1)),
        [footerNavigationParams]
    )
    return (
        <IonContent>
            <IonCard
                class='sg-centered-horizontal'
                style={{ maxWidth: '1000px', whiteSpace: 'pre-line' }}
            >
                {loading ? (
                    <IonCardContent>
                        <IonSpinner name='crescent' />
                    </IonCardContent>
                ) : (
                    <>
                        {!cardHeaderContent ? undefined : (
                            <IonCardHeader>{cardHeaderContent}</IonCardHeader>
                        )}

                        <IonCardContent>{children}</IonCardContent>
                        {!arrowButtons?.length ? undefined : (
                            <IonFooter collapse='fade' style={{}}>
                                <IonToolbar>
                                    <IonButtons
                                        slot={footerNavigationParams?.leftToRight ? 'start' : 'end'}
                                    >
                                        {arrowButtons.map(arrowBtn => (
                                            <IonButton
                                                key={arrowBtn.link}
                                                disabled={arrowBtn.isDisabled}
                                                routerLink={arrowBtn.link}
                                            >
                                                <IonIcon slot='icon-only' icon={arrowBtn.icon} />
                                            </IonButton>
                                        ))}
                                    </IonButtons>
                                </IonToolbar>
                            </IonFooter>
                        )}
                    </>
                )}
            </IonCard>
        </IonContent>
    )
}
