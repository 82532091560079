import { Subject } from 'rxjs'
import { Action0 } from '../types'

interface ToastParams {
    message: string
    opts?: {
        duration?: 'long'
        onPress?: Action0
    }
}

/** Should be subscribed by the platform level implementation */
export const onPlatformToast = new Subject<ToastParams>()

export function toast(
    message: string,
    opts?: {
        duration?: 'long'
    }
) {
    onPlatformToast.next({ message, opts })
    // Toast.show(message, opts?.duration === 'long' ? Toast.LONG : undefined)
}

export function toastLoginRequired() {
    // toast('You must be logged-in to do this') // temporarily disabled until further investigation
}
export function toastSelectText() {
    toast('Please select a text first')
}
