import { createAnimation, IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { Redirect, Route } from 'react-router-dom'
import { mainRouterProps } from '../shared-qurani-server-client/web/components/SharedQuraniComponents'
import { QuraniSideMenu } from '../shared-qurani-server-client/web/components/QuraniSideMenu'
import { ChaptersPage } from './pages/ChaptersPage'
import { VersePage } from './pages/VersePage'
import '../shared-qurani-server-client/web/styles/qurani-ionic-variables.css'
import '../shared-ts/web/styles/sg-common.scss'

setupIonicReact()

export function App() {
    return (
        <IonApp>
            <IonReactRouter>
                <QuraniSideMenu title='Quran' />
                <IonRouterOutlet {...mainRouterProps}>
                    <Route exact path='/:chapterId/:verseNbr' component={VersePage}></Route>
                    <Route exact path='/chapters' component={ChaptersPage}></Route>
                    <Route exact path='/'>
                        <Redirect to='/chapters' />
                    </Route>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    )
}
