export interface KathirByVerse {
    id: number
    chapterId: number
    verseNbr: number
    contents: string
}
export interface Kathir {
    id: number
    chapterId: number
    verseNbr: number
    itemNbr: number
    content: string
    isArabic: boolean
}
export interface Maududi {
    id: number
    chapterId: number
    verseNbr: number
    itemNbr: number
    verseFragment: string
    discussion: string
    startVerseNbr: number
    endVerseNbr: number
}
export interface Maarif {
    id: number
    chapterId: number
    verseNbr: number
    itemNbr: number
    content: string
    isArabic: boolean
    isHeader: boolean
}
export interface Sabab {
    id: number
    chapterId: number
    verseNbr: number
    content: string
}

/** This should never be changed after release */
export enum TafsirBookId {
    kathir = 1,
    sabab = 2,
    maududi = 3,
    maarif = 4,
}
export type TafsirSummary = {
    id: number
    bookId: TafsirBookId
    chapterId: number
    verseNbr: number
    summary: string
    title: string
}
