import {
    IonAccordion,
    IonAccordionGroup,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSkeletonText,
    IonText,
} from '@ionic/react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { KathirByVerse, Surah, Verse } from '../../shared-qurani-server-client'
import { dynamoDbWrapper } from '../../shared-qurani-server-client/services/DynamoDbWrapper'
import { quraniCognitoIdentityPoolId } from '../../shared-qurani-server-client/utils/Consts'
import { getVerseReferenceDisplay } from '../../shared-qurani-server-client/utils/ReferenceUtils'
import { QuraniToolbar } from '../../shared-qurani-server-client/web/components/QuraniToolbar'
import { QuraniItemMenu } from '../../shared-qurani-server-client/web/components/QuraniItemMenu'
import {
    QuraniFooterNavigationParams,
    QuraniPageContent,
} from '../../shared-qurani-server-client/web/components/QuraniPageContent'
import {
    getNextQuranVerseNbr,
    getPreviousQuranVerseNbr,
    handleError,
    throwIfNull,
    useDebouncedState,
} from '../../shared-ts'
import { Func0, SgMenu, WithStringValues } from '../../shared-ts/types/Types'
import { copyTextWithLinkFooter } from '../../shared-ts/utils/WebUtils'

// export function parseStringMap(
//     map: StringMap<string>,
//     typeMap: { [key in keyof typeof map]: 'number' | 'string' | 'boolean' }
// ) {
//     const parsed = {}
//     return Object.keys(map).reduce<{}>((a, b) => {
//         if(a)
//         return a
//     }, {})
// }

type SegmentLabel = 'Translation' | 'Tafsir' | 'Hadith'

export function VersePage(
    props: RouteComponentProps<WithStringValues<Verse, 'chapterId' | 'verseNbr'>>
) {
    const params = props.match.params
    const [verse, setverse] = useState<Verse | undefined>(undefined)
    const [chapter, setchapter] = useState<Surah | undefined>(undefined)
    const [kathirByVerse, setKathirByVerse] = useState<KathirByVerse | undefined>(undefined)
    const [loading, setloading] = useState(true)
    const [loadingTafsir, setloadingTafsir] = useState(true)
    const [openedSegment, setopenedSegment] = useState<SegmentLabel>('Translation')

    const chapterId = useMemo(() => parseInt(params.chapterId), [params.chapterId])
    const verseNbr = useMemo(() => parseInt(params.verseNbr), [params.verseNbr])
    const loadingDebounced = useDebouncedState(loading, 0)
    const loadingTafsirDebounced = useDebouncedState(loadingTafsir, 0)

    const referenceDisplay = useMemo(
        () =>
            chapter
                ? `${chapter.name} (${chapterId}:${verseNbr})`
                : `Quran ${chapterId}:${verseNbr}`,
        [chapter, chapterId, verseNbr]
    )

    const copyMenus: SgMenu[] = useMemo(
        () =>
            [
                {
                    label: 'Copy Verse',
                    textToCopy: verse?.verseClean || '',
                },
                {
                    label: 'Copy Translation',
                    textToCopy: verse?.translation || '',
                },
                {
                    label: 'Copy All',
                    textToCopy: !verse ? '' : `${verse.verseClean}\n\n${verse.translation}`,
                },
            ].map(
                (x): SgMenu => ({
                    title: x.label,
                    onClick: () => {
                        copyQuraniText(x.textToCopy, throwIfNull(verse)).catch(handleError)
                    },
                })
            ),
        [verse]
    )

    const segments = useMemo<
        {
            label: SegmentLabel
            renderSegment: Func0<ReactNode>
            show: boolean
        }[]
    >(
        () => [
            {
                label: 'Translation',
                renderSegment: () => <IonText>{verse?.translation}</IonText>,
                show: !!verse?.translation,
            },
            {
                label: 'Tafsir',
                show: loadingTafsirDebounced || !!kathirByVerse?.contents,
                renderSegment: () =>
                    loadingTafsirDebounced ? (
                        <IonSkeletonText animated />
                    ) : (
                        <IonText style={{}}>{kathirByVerse?.contents}</IonText>
                    ),
            },
            // {
            //     label: 'Hadith',
            //     renderSegment: () => undefined,
            // },
        ],
        [kathirByVerse?.contents, loadingTafsirDebounced, verse?.translation]
    )

    const footerNavigationParams = useMemo<QuraniFooterNavigationParams>(
        () => ({
            previous: {
                link: `/${chapterId}/${getPreviousQuranVerseNbr(
                    verseNbr,
                    chapter?.verseCount ?? Number.MAX_SAFE_INTEGER
                )}`,
                isDisabled: verseNbr <= 1,
            },
            next: {
                link: `/${chapterId}/${getNextQuranVerseNbr(
                    verseNbr,
                    chapter?.verseCount ?? Number.MAX_SAFE_INTEGER
                )}`,
                isDisabled: !chapter || verseNbr >= chapter.verseCount,
            },
        }),
        [chapter, chapterId, verseNbr]
    )

    useEffect(() => {
        document.title = referenceDisplay
    }, [referenceDisplay])

    useEffect(() => {
        setloading(true)
        setloadingTafsir(true)
        setverse(undefined)
        setKathirByVerse(undefined)
        // fetch(`https://d39k3ohet5eqjp.cloudfront.net/Verses/${chapterId}-${verseNbr}.json`)
        //     .then(async response => {
        //         const item: Verse = await response.json()
        //         setverse(item)
        //     })
        //     .finally(() => {
        //         setloading(false)
        //     })
        dynamoDbWrapper
            .queryPublic({
                cognitoIdentityPoolId: quraniCognitoIdentityPoolId,
                tableName: 'Verses',
                queryParams: {
                    chapterId,
                    verseNbr,
                },
                indexNameOverride: undefined,
            })
            .then(result => {
                setTimeout(() => {
                    setverse(result.Items?.[0] as Verse)
                    setloading(false)

                    dynamoDbWrapper
                        .batchGetPublic({
                            cognitoIdentityPoolId: quraniCognitoIdentityPoolId,
                            queriesByTable: {
                                KathirByVerse: [
                                    {
                                        chapterId,
                                        verseNbr,
                                    },
                                ],
                            },
                        })
                        .then(result => {
                            setTimeout(() => {
                                const item = result.Responses?.['KathirByVerse']?.[0] as
                                    | KathirByVerse
                                    | undefined
                                if (item) {
                                    setKathirByVerse(item)
                                }

                                setloadingTafsir(false)
                            }, 500)
                        })
                        .catch(console.error)
                }, 500)
            })
            .catch(console.error)
    }, [chapterId, verseNbr])

    useEffect(() => {
        dynamoDbWrapper
            .getPublic({
                cognitoIdentityPoolId: quraniCognitoIdentityPoolId,
                tableName: 'Surahs',
                queryParams: {
                    id: chapterId,
                },
            })
            .then(result => {
                setchapter(result.Item as Surah)
            })
            .catch(console.error)
    }, [chapterId])

    return (
        <IonPage>
            <QuraniToolbar title={referenceDisplay} backParams={{ type: 'custom', url: '/' }} />
            <QuraniPageContent
                loading={loadingDebounced}
                footerNavigationParams={footerNavigationParams}
            >
                <IonGrid>
                    <IonRow class='ion-align-items-center'>
                        <IonCol size='auto'>
                            <IonText style={{}}>
                                <h2>{referenceDisplay}</h2>
                            </IonText>
                        </IonCol>
                        <IonCol></IonCol>
                        <IonCol size='auto'>
                            <QuraniItemMenu menus={copyMenus} />
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-margin-top' style={{ lineHeight: 2 }}>
                        <IonCol class='ion-text-right'>
                            <IonText
                                style={{
                                    fontSize: '30px',
                                    fontFamily: 'Amiri',
                                    // fontFamily: 'Noto Sans Arabic',
                                    // fontFamily: 'Mirza',
                                }}
                            >
                                {verse?.verseClean}
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonAccordionGroup
                                expand='compact'
                                value={openedSegment}
                                onIonChange={ev => {
                                    setopenedSegment(ev.detail.value)
                                }}
                            >
                                {segments.map(segment =>
                                    !segment.show ? undefined : (
                                        <IonAccordion key={segment.label} value={segment.label}>
                                            <IonItem slot='header'>
                                                <IonLabel>{segment.label}</IonLabel>
                                            </IonItem>

                                            <IonItem slot='content' lines='none'>
                                                {segment.renderSegment()}
                                            </IonItem>
                                        </IonAccordion>
                                    )
                                )}
                            </IonAccordionGroup>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </QuraniPageContent>
        </IonPage>
    )
}

async function copyQuraniText(text: string, verse: Verse) {
    await copyTextWithLinkFooter(text, {
        url: `https://quran.qurani.co/${verse.chapterId}/${verse.verseNbr}`,
        display: `[${getVerseReferenceDisplay(verse)}]`,
    })
}
