// https://support.google.com/analytics/answer/9267735
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_content
declare function gtag(type: 'event', name: 'select_content', params: unknown): void

export function logAnalyticsSelectContent(params: { type: string; id: string }) {
    const { type, id } = params
    gtag('event', 'select_content', {
        content_type: type,
        item_id: id,
    })
}

export function logAnalyticsOutboundLink(link: string) {
    logAnalyticsSelectContent({
        type: 'outbound_link',
        id: link,
    })
}
