// TODO move this to web impl module
export async function copyText(text: string) {
    await navigator.clipboard.writeText(text)
}

export async function copyTextWithLinkFooter(
    text: string,
    linkParams: { url: string; display: string }
) {
    const { url, display } = linkParams
    const content = `${text
        .split('\n')
        .filter(x => !!x.trim())
        .map(x => `<p>${x}</p>`)
        .join('')}<p><a href="${url}">${display}</a></p>`
    const blob = new Blob([content], { type: 'text/html' })
    const clipboardItem = new window.ClipboardItem({
        'text/html': blob,
    })
    await navigator.clipboard.write([clipboardItem])
}
