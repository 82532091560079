import {
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { logoApple } from 'ionicons/icons'
import { useMemo } from 'react'
import { logAnalyticsOutboundLink } from '../web-utils/Analytics'

export interface LinkWithIcon {
    link: string
    icon: string
    title: string
    additionalClickHandler?: () => {}
}

export const quraniIosAppLink: LinkWithIcon = {
    link: 'https://apps.apple.com/us/app/qurani-quran-hadith-tafsir/id1536292666',
    icon: logoApple,
    title: 'Qurani iOS App',
    additionalClickHandler: () => {
        logAnalyticsOutboundLink(quraniIosAppLink.link)
        return false
    },
}

export function QuraniSideMenu(props: { title: string }) {
    const { title } = props

    const links = useMemo<LinkWithIcon[]>(
        () => [
            quraniIosAppLink,
            // {
            //     link: 'https://hadith.qurani.co',
            //     icon: book,
            //     title: 'Hadith',
            // },
            // {
            //     link: 'https://prayer.qurani.co',
            //     icon: time,
            //     title: 'Prayer Times',
            // },
        ],
        []
    )

    return (
        <IonMenu side='end' menuId='first' contentId='side-menu-content' type='overlay'>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle>{title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {links.map(link => (
                        <IonItem
                            key={link.title}
                            href={link.link}
                            target='_blank'
                            onClick={quraniIosAppLink.additionalClickHandler}
                        >
                            <IonLabel>{link.title}</IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonMenu>
    )
}
