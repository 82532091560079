import { createContext, useContext } from 'react'

const base = {
    primaryDark: '#0f4c75',
    primaryLight: '#3282b8',
    accent: '#800D13',
    dark10: '#111',
    dark9: '#151515',
    dark5: '#222',
    dark1: '#313131',
    dark0: '#333',
    light10: '#fff',
    light9: '#f9f9f9',
    light8: '#f2f2f2',
    light5: '#eee',
    light0: '#ddd',
    gray: '#777',
    lightGray: '#cfcfcf',

    blueSelected: '#bde3ff66',
    blueSelectedDarker: '#1e88e5',

    danger: '#c33',
    warning: '#FDD835',
    transparent: 'transparent',

    greenCorrect: '#00990066',
    redWrong: '#99000066',
}

const common = {
    // todo: test these for both themes and see if needs adjustments
    buttonDanger: base.danger,
    buttonDangerText: base.light10,
    textBlue: '#269',
    textError: '#c33',
    dotHighlight: '#269',
    bgCorrect: base.greenCorrect,
    bgWrong: base.redWrong,
    transparent: base.transparent,
    gold: '#FFD700',
    darkGold: '#b29600',
    premiumText: '#998100',
    premiumBackground: '#fffbe5',

    redText: '#C40234',

    google: '#b51b0b',
    apple: 'black',
    facebook: '#0f4c81',
    twitter: '#0084b4',
    passwordLogin: '#228B22',
} as const

const defaultTheme = {
    ...common,

    nav: base.primaryDark,
    navSecondary: base.primaryLight,
    navText: base.light10,
    navIcon: base.light10,

    header: base.primaryLight,
    headerText: base.light10,

    routerRoot: base.light9,
    screenRoot: base.light10,

    bg: base.light10,
    bgAlternate: base.light9,
    bgAlternateBorder: base.light0,
    bgSelected: base.light5,
    bgSelectedColorful: base.blueSelected,

    errorBg: base.light9,
    errorText: base.danger,

    decoration: base.dark10,
    decorationText: base.light10,

    borderFrame: base.primaryLight,

    button: base.primaryDark,
    buttonText: base.light10,
    buttonOptionalBorder: base.dark0,
    buttonSecondary: base.accent,
    buttonSecondaryText: base.light10,
    buttonEmphasis: base.dark10,
    buttonEmphasisText: base.light10,
    buttonOutline: base.light9,
    buttonOutlineText: base.dark5,
    buttonOutlineBorder: base.gray,
    buttonFlat: base.transparent,
    buttonFlatText: base.dark5,
    buttonDisabled: base.lightGray,
    buttonDisabledText: base.light10,
    buttonDisabledBorder: base.light5,

    invert: base.dark5,
    invertText: base.light9,
    invertBorder: base.dark0,

    input: base.light10,
    inputIconDisplay: base.gray,
    inputPlaceholder: base.gray,
    inputText: base.dark5,

    highlight: 'yellow',

    text: base.dark0,
    textHint: base.gray,
    textDecoration: base.accent,
    textPremium: base.accent,
    icon: base.dark0,
    iconSelected: base.blueSelectedDarker,
    iconDisabled: base.gray,
    iconDisplay: base.gray,
    border: base.light5,

    textWarning: base.dark0,
    borderWarning: base.warning,

    spinner: base.primaryLight,
    // progress: base.primaryLight,
    // progressTrack: base.dark5,
    progress: base.gray,
    progressTrack: base.lightGray,
}

export type AppTheme = typeof defaultTheme

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const invertedTheme: AppTheme = {
    ...common,
    nav: base.primaryDark,
    navSecondary: base.primaryLight,
    navText: base.light10,
    navIcon: base.light10,

    header: base.dark5,
    headerText: base.light10,

    routerRoot: base.light9,
    screenRoot: base.dark10,

    bg: base.dark9,
    bgAlternate: base.dark5,
    bgAlternateBorder: base.dark0,
    bgSelected: base.dark1,
    bgSelectedColorful: base.dark1,

    errorBg: base.light9,
    errorText: base.danger,

    decoration: base.light9,
    decorationText: base.dark5,

    borderFrame: base.primaryLight,

    button: base.primaryDark,
    buttonText: base.light10,
    buttonOptionalBorder: base.light5,
    buttonSecondary: base.accent,
    buttonSecondaryText: base.light10,
    buttonEmphasis: base.dark10,
    buttonEmphasisText: base.light10,
    buttonOutline: base.light9,
    buttonOutlineText: base.dark5,
    buttonOutlineBorder: base.gray,
    buttonFlat: base.transparent,
    buttonFlatText: base.dark5,
    buttonDisabled: base.lightGray,
    buttonDisabledText: base.dark5,
    buttonDisabledBorder: base.gray,

    invert: base.dark5,
    invertText: base.light9,
    invertBorder: base.dark0,

    input: base.dark9,
    inputIconDisplay: base.gray,
    inputPlaceholder: base.gray,
    inputText: base.light10,

    highlight: 'yellow',

    text: base.light10,
    textHint: base.gray,
    textDecoration: base.accent,
    textPremium: base.accent,
    icon: base.light10,
    iconSelected: base.blueSelectedDarker,
    iconDisabled: base.gray,
    iconDisplay: base.gray,
    border: base.dark0,

    textWarning: base.light10,
    borderWarning: base.warning,

    spinner: base.primaryLight,
    progress: base.primaryLight,
    progressTrack: base.light5,
}

const context = createContext(defaultTheme)
export function useAppTheme() {
    return useContext(context)
}
