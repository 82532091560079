import { StringMap } from '../../shared-ts'

export const hadithBooksMap: StringMap<string> = {
    1: 'Sahih Bukhari',
    2: 'Sahih Muslim',
    3: 'Sunan Nasai',
    4: 'Sunan Abi Dawud',
    5: 'Jamiat Tirmidhi',
    6: 'Sunan Ibn Majah',
    7: 'Muwatta Malik',
    8: 'Musnad Ahmad',
    9: '40 Hadith Nawawi',
    10: 'Riyad Assalihin',
    11: 'Mishkat Al-Masabih',
    12: 'Al-Adab Al-Mufrad',
    13: '40 Hadith Qudsi',
    14: 'Ash-Shamail Al-Muhammadiyah',
    15: 'Bulugh Al-Maram',
}

export const quranSurahsMap: StringMap<string> = {
    1: 'Al-Fatihah',
    2: 'Al-Baqarah',
    3: 'Al Imran',
    4: "An-Nisa'",
    5: "Al-Ma'idah",
    6: "Al-An'am",
    7: "Al-A'raf",
    8: 'Al-Anfal',
    9: 'At-Tawbah',
    10: 'Yunus',
    11: 'Hud',
    12: 'Yusuf',
    13: "Ar-Ra'd",
    14: 'Ibraheem',
    15: 'Al-Hijr',
    16: 'An-Nahl',
    17: 'Al-Isra',
    18: 'Al-Kahf',
    19: 'Maryam',
    20: 'Ta-Ha',
    21: "Al-Anbiya'",
    22: 'Al-Hajj',
    23: "Al-Mu'minoon",
    24: 'An-Nur',
    25: 'Al-Furqan',
    26: "Ash-Shu`ara'",
    27: 'An-Naml',
    28: 'Al-Qasas',
    29: 'Al-`Ankabut',
    30: 'Ar-Rum',
    31: 'Luqman',
    32: 'As-Sajdah',
    33: 'Al-Ahzab',
    34: "Saba'",
    35: 'Fatir',
    36: 'Ya seen',
    37: 'As-Saffat',
    38: 'Sad',
    39: 'Az-Zumar',
    40: 'Ghafir',
    41: 'Fussilat',
    42: 'Ash-Shura',
    43: 'Az-Zukhruf',
    44: 'Ad-Dukhan',
    45: 'Al-Jathiyah',
    46: 'Al-Ahqaf',
    47: 'Muhammad',
    48: 'Al-Fath',
    49: 'Al-Hujurat',
    50: 'Qaf',
    51: 'Ad-Dhariyat',
    52: 'At-Tur',
    53: 'An-Najm',
    54: 'Al-Qamar',
    55: 'Ar-Rahman',
    56: "Al-Waqi'ah",
    57: 'Al-Hadeed',
    58: 'Al-Mujadilah',
    59: 'Al-Hashr',
    60: 'Al-Mumtahanah',
    61: 'As-Saff',
    62: "Al-Jumu'ah",
    63: 'Al-Munafiqun',
    64: 'At-Taghabun',
    65: 'At-Talaq',
    66: 'At-Tahreem',
    67: 'Al-Mulk',
    68: 'Al-Qalam',
    69: 'Al-Haqqah',
    70: "Al-Ma'aarij",
    71: 'Nuh',
    72: 'Al-Jinn',
    73: 'Al-Muzzammil',
    74: 'Al-Muddathir',
    75: 'Al-Qiyamah',
    76: 'Al-Insan',
    77: 'Al-Mursalat',
    78: "An-Naba'",
    79: "An-Nazi'at",
    80: 'Abasa',
    81: 'At-Takweer',
    82: 'Al-Infitar',
    83: 'Al-Mutaffifeen',
    84: 'Al-Inshiqaq',
    85: 'Al-Burooj',
    86: 'At-Tariq',
    87: "Al-A'la",
    88: 'Al-Ghashiyah',
    89: 'Al-Fajr',
    90: 'Al-Balad',
    91: 'Ash-Shams',
    92: 'Al-Lail',
    93: 'Ad-Dhuha',
    94: 'Al-Inshirah',
    95: 'Al-Teen',
    96: 'Al-`Alaq',
    97: 'Al-Qadr',
    98: 'Al-Bayyinah',
    99: 'Az-Zalzala',
    100: 'Al-Adiyat',
    101: 'Al-Qari`ah',
    102: 'At-Takathur',
    103: 'Al-Asr',
    104: 'Al-Humazah',
    105: 'Al-Feel',
    106: 'Al-Quraish',
    107: "Al-Maa'oun",
    108: 'Al-Kawthar',
    109: 'Al-Kafiroun',
    110: 'An-Nasr',
    111: 'Al-Masad',
    112: 'Al-Ikhlas',
    113: 'Al-Falaq',
    114: 'Al-Nas',
}
