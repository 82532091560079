import { IonItem, IonLabel, IonList, IonPage, IonSearchbar } from '@ionic/react'
import { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { quranSurahsMap } from '../../shared-qurani-server-client/utils/QuranConsts'
import { QuraniPageContent } from '../../shared-qurani-server-client/web/components/QuraniPageContent'
import { QuraniToolbar } from '../../shared-qurani-server-client/web/components/QuraniToolbar'
import { getKeyValuePairs, useDebouncedState, useDocumentTitle } from '../../shared-ts'

export function ChaptersPage(_: RouteComponentProps<{}>) {
    const [loading] = useState(false)
    const [searchString, setsearchString] = useState('')

    const loadingDebounced = useDebouncedState(loading, 0)

    const title = 'Quran Chapters'

    const chapters = useMemo(() => {
        return getKeyValuePairs(quranSurahsMap)
            .map(kv => {
                return {
                    ...kv,
                    chapterId: parseInt(kv.key),
                    chapterIdLowerCase: kv.key.toLowerCase(),
                    chapterNameLowerCase: kv.value?.toLowerCase() || '',
                }
            })
            .sort((a, b) => a.chapterId - b.chapterId)
    }, [])

    const filteredChapters = useMemo(() => {
        const searchLowerCased = searchString.toLowerCase()
        return chapters.filter(
            chapter =>
                chapter.chapterIdLowerCase.includes(searchLowerCased) ||
                chapter.chapterNameLowerCase.includes(searchLowerCased)
        )
    }, [chapters, searchString])

    useDocumentTitle(title)

    return (
        <IonPage>
            <QuraniToolbar title={title} />
            <QuraniPageContent
                loading={loadingDebounced}
                cardHeaderContent={
                    <IonSearchbar
                        animated
                        value={searchString}
                        onIonChange={ev => setsearchString(ev.detail.value || '')}
                        debounce={200}
                    />
                }
            >
                <IonList>
                    {filteredChapters.map(chapter => (
                        <IonItem key={chapter.chapterId} routerLink={`/${chapter.chapterId}/1`}>
                            <IonLabel position='fixed'>{chapter.chapterId}</IonLabel>
                            <IonLabel>{chapter.value}</IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </QuraniPageContent>
        </IonPage>
    )
}
