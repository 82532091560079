import { createContext, useContext } from 'react'
import { BehaviorSubject } from 'rxjs'
import en from '../strings/en'

export {}
export type Strings = typeof en
const context = createContext(en)
const stringsSubject = new BehaviorSubject<Strings>(en)

export function getStrings() {
    return stringsSubject
}

export function useStrings() {
    return useContext(context)
}
