export const commonStrings = {
    // bismillah: 'بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ',
    bismillah: '\ufdfd',
    bullet: '\u2022',
    salawat: '\uFDFA',
    sajda: '۩', // \u06E9
    arLeftParenthesis: '\uFD3E',
    arRightParethesis: '\uFD3F',
    ayahEnd: '\u06DD',
} as const

export const commonFonts = {
    comfortaaBold: 'Comfortaa-Bold',
    comfortaaRegular: 'Comfortaa-Regular',
    openSansBold: 'OpenSans-Bold',
    openSansRegular: 'OpenSans-Regular',
} as const

// export const emptySpaceArabic = '          ' as const
export const emptySpaceArabic = '   ' as const

export const emptyHourMinuteSeconds = '00:00:00'

export const arabicDot = '\u0660' // ٠
export const arabicEllipsis = arabicDot + arabicDot + arabicDot
export const ellipsis = '\u2026'
export const middleDot = '\u22c5' // interpunct
export const bullet = '\u2022'
