/* eslint-disable no-extend-native */
export {}
declare global {
    interface Array<T> {
        getLast(): T | null
        getFirst(): T | null
        getLastIndex(): number | null
        isEmpty(): boolean
    }
    interface String {
        isBlank(): boolean
    }
}

if (!Array.prototype.getLast) {
    Array.prototype.getLast = function () {
        if (this.length === 0) {
            return null
        }

        return this[this.length - 1]
    }
}

if (!Array.prototype.getFirst) {
    Array.prototype.getFirst = function () {
        if (this.length === 0) {
            return null
        }

        return this[0]
    }
}

if (!Array.prototype.getLastIndex) {
    Array.prototype.getLastIndex = function () {
        if (this.length === 0) {
            return null
        }

        return this.length - 1
    }
}

if (!Array.prototype.isEmpty) {
    Array.prototype.isEmpty = function () {
        return this.length === 0
    }
}

if (!String.prototype.isBlank) {
    String.prototype.isBlank = function () {
        return this.trim() === ''
    }
}
