import { DynamoDBClient } from '@aws-sdk/client-dynamodb'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import {
    BatchGetCommand,
    BatchGetCommandInput,
    DynamoDBDocumentClient,
    GetCommand,
    QueryCommand,
    ScanCommand,
} from '@aws-sdk/lib-dynamodb'
import { SerializableMap, StringMap } from '../../shared-ts'

export const dynamoDbWrapper = {
    queryPublic: (params: {
        cognitoIdentityPoolId: string
        tableName: string
        queryParams: SerializableMap
        indexNameOverride: string | undefined // use empty string to query the table (haven't tested if it will work)
    }) => {
        const { cognitoIdentityPoolId, tableName, queryParams, indexNameOverride } = params
        const client = getPublicClientInstance(cognitoIdentityPoolId)

        const keys = Object.keys(queryParams).sort((a, b) => a.localeCompare(b))
        const cmd = new QueryCommand({
            TableName: tableName, // 'Verses',
            IndexName: indexNameOverride ?? `${keys.join('-')}-index`, // 'chapterId-verseNbr-index',
            KeyConditionExpression: keys.map(x => `${x} = :${x}`).join(' and '), //'chapterId = :chapterId and verseNbr = :verseNbr',
            ExpressionAttributeValues: keys.reduce<SerializableMap>((a, b) => {
                a[`:${b}`] = queryParams[b]
                return a
            }, {}),
        })

        return client.send(cmd)
    },
    getPublic: (params: {
        cognitoIdentityPoolId: string
        tableName: string
        queryParams: SerializableMap
    }) => {
        const { cognitoIdentityPoolId, tableName, queryParams } = params
        const client = getPublicClientInstance(cognitoIdentityPoolId)

        const cmd = new GetCommand({
            TableName: tableName, // 'Verses',
            Key: queryParams,
        })

        return client.send(cmd)
    },
    batchGetPublic: (params: {
        cognitoIdentityPoolId: string
        queriesByTable: StringMap<SerializableMap[]>
    }) => {
        const { cognitoIdentityPoolId, queriesByTable } = params
        const client = getPublicClientInstance(cognitoIdentityPoolId)

        const cmd = new BatchGetCommand({
            RequestItems: Object.keys(queriesByTable).reduce<
                Exclude<BatchGetCommandInput['RequestItems'], undefined>
            >((a, tableName) => {
                const queries = queriesByTable[tableName]
                a[tableName] = {
                    Keys: queries,
                }
                return a
            }, {}),
        })

        return client.send(cmd)
    },
    getAllPaginated: (params: { tableName: string; startKeys: SerializableMap | undefined }) => {
        const { tableName, startKeys: startKey } = params
        const client = getNonPublicClientInstance()

        const cmd = new ScanCommand({
            TableName: tableName, // 'Verses',
            ExclusiveStartKey: startKey,
        })

        return client.send(cmd)
    },
    getAllPublicPaginated: (params: {
        tableName: string
        startKeys: SerializableMap | undefined
        cognitoIdentityPoolId: string
    }) => {
        const { tableName, startKeys: startKey, cognitoIdentityPoolId } = params
        const client = getPublicClientInstance(cognitoIdentityPoolId)

        const cmd = new ScanCommand({
            TableName: tableName, // 'Verses',
            ExclusiveStartKey: startKey,
        })

        return client.send(cmd)
    },
}

let publicClientInstance: DynamoDBDocumentClient | undefined
function getPublicClientInstance(cognitoIdentityPoolId: string) {
    if (!publicClientInstance) {
        const region = 'us-east-1'
        const credentials = fromCognitoIdentityPool({
            identityPoolId: cognitoIdentityPoolId,
            clientConfig: { region },
        })
        publicClientInstance = DynamoDBDocumentClient.from(
            new DynamoDBClient({ region: region, credentials }),

            {
                marshallOptions: {
                    convertEmptyValues: false,
                    removeUndefinedValues: true,
                    convertClassInstanceToMap: true, // false, by default.
                },
                unmarshallOptions: {
                    wrapNumbers: false, // false, by default.
                },
            }
        )
    }

    return publicClientInstance
}

let nonPublicClientInstance: DynamoDBDocumentClient | undefined
function getNonPublicClientInstance() {
    if (!nonPublicClientInstance) {
        const region = 'us-east-1'
        nonPublicClientInstance = DynamoDBDocumentClient.from(
            new DynamoDBClient({ region: region }),

            {
                marshallOptions: {
                    convertEmptyValues: false,
                    removeUndefinedValues: true,
                    convertClassInstanceToMap: true, // false, by default.
                },
                unmarshallOptions: {
                    wrapNumbers: false, // false, by default.
                },
            }
        )
    }

    return nonPublicClientInstance
}
