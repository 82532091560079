import { Action0, CoordinatesSimple, Func0, SgRange } from '../../shared-ts'
import { Translation, VerseReference } from './models-sqlite'
import { InternalReference } from './ReferenceModels'

export type ListItemExtras =
    | undefined
    | Partial<{
          highlightWords: string[]
          tokenOccurencesInVerse: number
      }>

export type ListItem = {
    title: string | undefined
    english: string | undefined
    arabic: string | undefined
    onClick?: Action0 | undefined
    reference: InternalReference | undefined
    extras?: ListItemExtras
    num: number | undefined
    highlights?: {
        words?: string[]
        ranges?: SgRange[]
    }
    // isLocked?: boolean
    // isUnlocked: boolean
}

/**@deprecated Replace with ListItem */
export type TextSummary = {
    title: string
    summary: string
    isArabic: boolean
    onClick?: Action0
    // tokens?: undefined | TfIdfToken[]
    footer?: string
    // isLocked?: boolean
}

export type ListSection<T> = {
    title: string
    data: T[]
    sectionIndex?: number
}

// export enum SimilarityCategory {
//     identical = 'identical',
//     nearIdentical = 'near-identical',
//     highlyRelated = 'highly-related',
//     related = 'related',
//     someMatches = 'some-matches',
//     fewMatches = 'few-matches',
//     unrelated = 'unrelated',
// }
export enum SimilarityCategory {
    strong = 'strongly-similar',
    medium = 'moderately-similar',
    weak = 'weakly-similar',
    unrelated = 'unrelated',
}

export type SimilarItems = {
    itemIds: string
    // rating: number
    category: SimilarityCategory
    count: number
}

export type RootVerseSnippet = {
    tokenClean: string
    meaning: string
    verseClean: string
    verseSnippet: string
    chapterId: number
    verseNbr: number
}

export type EnglishMushafPage = {
    surahs: {
        translations: Translation[]
    }[]
}

export type PrayerTime = {
    displayName: string
    timeFormatted: string
    // timeObj: Moment
    absoluteHoursDecimal: number
    absoluteSeconds: number
    nextAbsoluteSecondsAdjusted: number
}

export type DetectedLocation = {
    checkIfDetected: Func0<boolean> | undefined
}

export type LocationWithAddress = CoordinatesSimple & {
    address: string | undefined
    // animateTo: false
    countryCode: string | undefined
} & DetectedLocation

export type Timezone = {
    offset: number
    name: string | undefined
}

export type BookmarkItem = {
    english: string | undefined
    arabic: string | undefined
    reference: InternalReference
    tags: string[] | undefined
    title: string
    key?: string
    formattedDate: string | undefined
}
export type BookmarkTypes = 'quran' | 'hadith' | 'highlights'

export type VerseSnippet = VerseReference & VerseSnippetParam

export type VerseSnippetParam = {
    rangesString: string
    verseClean: string
    count: number
}

export type GameDifficulty = 'easy' | 'normal'
