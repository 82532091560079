import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
} from '@ionic/react'
import { copyOutline, ellipsisHorizontalOutline } from 'ionicons/icons'
import { SgMenu } from '../../../shared-ts/types/Types'

export function QuraniItemMenu(props: { menus: SgMenu[] }) {
    const { menus } = props
    return (
        <>
            <IonButton id='item-menu-button'>
                <IonIcon icon={ellipsisHorizontalOutline} />
            </IonButton>
            <IonPopover trigger='item-menu-button' dismissOnSelect showBackdrop={false}>
                <IonContent>
                    <IonList>
                        {menus.map(menu => (
                            <IonItem
                                key={menu.title}
                                button
                                detail={false}
                                onClick={async () => {
                                    menu.onClick()
                                }}
                                lines='full'
                            >
                                <IonIcon icon={copyOutline} slot='start' />
                                <IonLabel>{menu.title}</IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </IonContent>
            </IonPopover>
        </>
    )
}
