import {
    HadithChapter,
    HadithChapterReference,
    HadithInternalReference,
    HadithReference,
    InternalReference,
    QuranTokenReference,
    TafsirBookId,
    TafsirInternalReference,
    VerseRangeReference,
    VerseReference,
} from '../../shared-qurani-server-client/models'
import { throwIfNull } from '../../shared-ts'
import { hadithBooksMap, quranSurahsMap } from './QuranConsts'

export function getVerseReference(verse: VerseReference): InternalReference {
    return {
        prefix: 'qr',
        chapterId: verse.chapterId,
        verseNbr: verse.verseNbr,
    }
}

export function getTafsirReference(
    verse: VerseReference,
    bookId: TafsirBookId,
    itemNbr: number | undefined
): TafsirInternalReference {
    return {
        prefix: 'tf',
        chapterId: verse.chapterId,
        verseNbr: verse.verseNbr,
        bookId,
        itemNbr,
    }
}
export function getTafsirReferenceString(tafsirRef: TafsirInternalReference) {
    return `${TafsirBookId[tafsirRef.bookId]}-${tafsirRef.chapterId}-${tafsirRef.verseNbr}${
        tafsirRef.itemNbr === undefined ? '' : `-${tafsirRef.itemNbr}`
    }`
}
export function getHadithReference(item: HadithReference): HadithInternalReference {
    return {
        prefix: 'hd',
        chapterId: item.chapterId,
        bookId: item.bookId,
        itemNbr: item.itemNbr,
    }
}

// forward slash can't be used as key in firestore
export function getVerseReferenceString(params: VerseReference) {
    const { chapterId, verseNbr } = params
    return `${chapterId}-${verseNbr}`
}
export function getVerseReferenceDisplay(params: VerseReference) {
    const { chapterId, verseNbr } = params
    // return `${titlePrefix} ${quranSurahsMap[chapterId]} (${chapterId}:${verseNbr})`
    return `${quranSurahsMap[chapterId]} ${chapterId}:${verseNbr}`
}
export function getQuranTokenReferenceDisplay(params: QuranTokenReference) {
    const { tokenNbr, chapterId, verseNbr } = params
    // return `${titlePrefix} ${quranSurahsMap[chapterId]} (${chapterId}:${verseNbr})`
    return `${quranSurahsMap[chapterId]} ${chapterId}:${verseNbr}:${tokenNbr}`
}
export function getVerseRangeReferenceDisplay(params: VerseRangeReference) {
    const { chapterId, startVerseNbr, endVerseNbr } = params
    // Tafsir Maududi ({quranSurahsMap[chapterId]} {chapterId}:
    //     {startVerseNbr}
    //     {startVerseNbr !== endVerseNbr ? `-${endVerseNbr}` : ''})
    // return `${titlePrefix} ${quranSurahsMap[chapterId]} (${chapterId}:${verseNbr})`
    return `${quranSurahsMap[chapterId]} ${chapterId}:${startVerseNbr}${
        startVerseNbr !== endVerseNbr ? `-${endVerseNbr}` : ''
    }`
}
// forward slash can't be used as key in firestore
export function getVerseTokenReferenceString(params: QuranTokenReference) {
    const { chapterId, verseNbr, tokenNbr } = params
    return `${chapterId}-${verseNbr}-${tokenNbr}`
}

export function getHadithReferenceString(params: HadithReference) {
    const { chapterId, bookId, itemNbr } = params
    return `${bookId}-${chapterId}-${itemNbr}`
}

export function getHadithChapterReferenceString(chapter: HadithChapterReference) {
    const { chapterId, bookId } = chapter
    return `${bookId}-${chapterId}`
}

// export function isQuranNote(item: Note): item is QuranNote {
//     return item.prefix === 'qr'
// }

export function getHadithChapterTitleDisplay(chapter: HadithChapter): string {
    return chapter.titleEn ?? chapter.titleAr ?? `Chapter ${chapter.chapterId}`
}
export function getHadithChapterReferenceDisplay(chapter: HadithChapterReference): string {
    return `${hadithBooksMap[chapter.bookId]} ${chapter.chapterId}`
}

export function getHadithReferenceDisplay(hadith: HadithReference): string {
    // return `Hadith ${hadith.bookId} (${hadith.chapterId}:${hadith.itemNbr})`
    return `${hadithBooksMap[hadith.bookId]} ${hadith.chapterId}:${hadith.itemNbr}`
}

export function checkIfSameHadithReference(ref1: HadithReference, ref2: HadithReference) {
    return (
        ref1.bookId === ref2.bookId &&
        ref1.chapterId === ref2.chapterId &&
        ref1.itemNbr === ref2.itemNbr
    )
}

export function referenceToArray(reference: InternalReference) {
    return [
        reference.prefix,
        reference.bookId,
        reference.chapterId,
        reference.verseNbr,
        reference.itemNbr,
        reference.pageNbr,
    ]
}

export function getReferenceString(reference: InternalReference) {
    const arr: string[] = [reference.prefix]
    const { bookId, chapterId, verseNbr, itemNbr } = reference
    if (bookId !== undefined) {
        arr.push(bookId.toString())
    }

    if (chapterId !== undefined) {
        arr.push(chapterId.toString())
    }

    if (verseNbr !== undefined) {
        arr.push(verseNbr.toString())
    }

    if (itemNbr !== undefined) {
        arr.push(itemNbr.toString())
    }

    return arr.join('-')
}

// export function getPrefixDisplay(reference: InternalReference) {
//     switch (reference.prefix) {
//         case 'qr':
//             return 'Quran'
//         case 'tf':
//             return 'Tafsir'
//         case 'hd':
//             // TODO: hard code hadith books just like tafsir
//             return 'Hadith'
//         case 'hdb':
//             return 'Hadith Books'
//         case 'tn':
//             return 'Translation'
//     }
// }

export function getTafsirBookName(bookId: TafsirBookId) {
    switch (bookId) {
        case TafsirBookId.maududi:
            return 'Tafsir Maududi'
        case TafsirBookId.maarif:
            return 'Maariful Quran'
        case TafsirBookId.sabab:
            return 'Sabab Annuzul'
        case TafsirBookId.kathir:
            return 'Tafsir Ibn Kathir'
    }
}

export function getTafsirTableName(bookId: TafsirBookId) {
    switch (bookId) {
        case TafsirBookId.maududi:
            return 'Maududi'
        case TafsirBookId.maarif:
            return 'Maarif'
        case TafsirBookId.sabab:
            return 'Sabab'
        case TafsirBookId.kathir:
            return 'Kathir'
    }
}

export function getReferenceDisplay(reference: InternalReference): string {
    const { bookId, chapterId, verseNbr, itemNbr } = reference
    switch (reference.prefix) {
        case 'qr':
            return getVerseReferenceDisplay({
                chapterId: throwIfNull(chapterId),
                verseNbr: throwIfNull(verseNbr),
            })
        case 'tf':
            return getTafsirReferenceDisplay(bookId as TafsirBookId, reference as VerseReference)
        case 'hd':
        case 'hds':
            return getHadithReferenceDisplay({
                bookId: throwIfNull(bookId),
                chapterId: throwIfNull(chapterId),
                itemNbr: throwIfNull(itemNbr),
            })
        case 'hdb':
            return throwIfNull(hadithBooksMap[throwIfNull(bookId)])
        case 'qrs':
            // TODO change this. Make a map for the surahs
            return `Quran ${chapterId}`
        case 'tn':
            return getVerseReferenceDisplay({
                chapterId: throwIfNull(chapterId),
                verseNbr: throwIfNull(verseNbr),
            })
    }

    // const arr: string[] = []
    // const { bookId, chapterId, verseNbr, itemNbr } = reference
    // if (bookId !== undefined) {
    //     arr.push(bookId.toString())
    // }

    // if (chapterId !== undefined) {
    //     arr.push(chapterId.toString())
    // }

    // if (verseNbr !== undefined) {
    //     arr.push(verseNbr.toString())
    // }

    // // TODO: itemNbr is sometimes null
    // if (itemNbr !== undefined && itemNbr !== null) {
    //     arr.push(itemNbr.toString())
    // }
    // return `${getPrefixDisplay(reference)} ${arr.join(':')}`
}

export function getTafsirReferenceDisplay(bookId: TafsirBookId, reference: VerseReference) {
    return `${getTafsirBookName(bookId as TafsirBookId)} (${getVerseReferenceDisplay({
        chapterId: reference.chapterId,
        verseNbr: throwIfNull(reference.verseNbr),
    })})`
}
